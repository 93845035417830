import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
// import imageUrlBuilder from "@sanity/image-url";
import PropagateLoader from "react-spinners/PropagateLoader";


const FeaturedBlog = () => {
//   const builder = imageUrlBuilder(sanityClient);
//   function urlFor(source) {
//     return builder.image(source);
//   }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function refreshPage() {
    // window.location.reload(false);
    window.scrollTo(0, 0);
  }

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }

  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "post"  && slug.current!="${slug}"]{
          title,
          slug,
          body,
          location,
          publishedAt,
          mainImage{
              asset->{
                  _id,
                url
                      }
                    },
          "name":author->name,
          "authorImage":author->image
        }`
      )
      .then((data) => setSinglePost(data[getRandomInt(data.length)]))
      .catch(console.error);
  }, [slug]);
  if (!singlePost)
    return (
      <div className="my-5 text-center">
        <PropagateLoader size={5} />
      </div>
    );

  return (
    <>
      <div className="row">
        <div className="col sidebar-blog-heading  my-4">
          <h1>Featured <br/><span style={{color:"#0060B8"}}>Blog</span> </h1>
          <hr className="w-25" />
        </div>
      </div>
      <div className="row">
        <div className="col" onClick={refreshPage}>
          <Link to={"/blogs/" + singlePost.slug.current}>
            <div className="card border-0 card-effect" key={singlePost.slug.current}>
              <img
                className="img-fluid rounded card-img-top"
                src={singlePost.mainImage.asset.url}
                alt={"Image for " + singlePost.title}
              />
              <div className="card-body pt-0">
                {/* blog Category and location */}
                <div className="row pt-2 blogCatLoct">
                  <div className="col">
                    <p className="p-0">{singlePost.location}</p>
                  </div>
                </div>

                <div className="card-title">
                  <b className="featured-blog-title">{singlePost.title}</b>
                </div>
                <>
                  <p className="featured-blog-body  mt-2">
                    {truncate(`"${singlePost.body[0].children[0].text}`, 160)}
                  </p>
                </>
                <p className="text-end">Read More{" >"} </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FeaturedBlog;
