const Contact = ({ contact }) => {
  const requiredCss = {
    color: "#FF0000",
  };

  return (
    <article>
      <div className="container-fluid contact-container" id="contact">
        <div className="container pb-5">
          <div className="col-md-12 pt-5 text-white">
            <h1>
              Connect <br /> to Me
            </h1>
            <hr className="w-25" />
          </div>
          <div className="col-md-12">
            <p className="text-white mt-5">{contact}</p>
          </div>

          <form
            name="contact"
            method="post"
            data-netlify-recaptcha="true"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <input name="bot-field" />
            </div>
            <div className="row mt-5 text-white">
              <div className="col-md-5  px-5">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Your Name (<span style={requiredCss}>*</span>)
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rohan Manandhar"
                    id="name"
                    name="name"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address (<span style={requiredCss}>*</span>)
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="yourName@example.com"
                    id="email"
                    name="email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="+977-98*-***-****"
                    id="phone"
                    name="phone"
                    
                  />
                </div>
              </div>

              <div className="col-md-1 contact-form  " />
              <div className="col-md-6 ">
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message(s) (<span style={requiredCss}>*</span>)
                  </label>
                  <textarea
                    className="form-control"
                    rows={3}
                    type="message"
                    placeholder="Your Text Message Here"
                    id="message"
                    name="message"
                    required
                  />
                </div>

                <div className="mb-3 text-end">
                  <input
                    type="submit"
                    value="Submit >"
                    className="btn btn-outline-light"
                  />
                </div>
              </div>
            </div>
          </form>

        
        </div>
      </div>
    </article>
  );
};

export default Contact;
