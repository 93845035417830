import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/GlobalComponents/Navbar";
import Footer from "./components/GlobalComponents/Footer";
import NotFound from "./components/GlobalComponents/404";
import Home from "./components/HomeComponents/Home";
import Blog from "./components/BlogComponents/Blog";
import SingleBlogPost from "./components/BlogComponents/SingleBlog";
import Helmet from "react-helmet";
// import publicImg from "../src/Images/bg.jpg";

function App() {
  return (
    <>
      <Helmet>
        {/* <title>Rohan M.</title> */}
 

        {/* <meta itemprop="image" property="og:image" name="twitter:image" content="aaa"/> */}
        {/* <link rel="canonical" href={window.location.href} />
        <meta property="og:url" content={window.location.href} /> */}
      </Helmet>
      <Router>
        <div className="App">
          <Navbar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/blogs" exact component={Blog} />
            <Route component={SingleBlogPost} exact path="/blogs/:slug" />
            <Route component={NotFound} />
          </Switch>

          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
