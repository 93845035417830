import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  ViberShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
  ViberIcon,
} from "react-share";
const ShareBlog = ({ link, title, author, imgLink }) => {
  return (
    <>
      <div className="col-md-12 ">
        <div>
          <h3>Share This Post On:</h3>
        </div>
        <div className="col">
          <LinkedinShareButton
            className="px-2 pt-1 "
            url={link}
            title={title}
            source={author}
          >
            <LinkedinIcon
           
              round={true}
              size={40}
            ></LinkedinIcon>
          </LinkedinShareButton>

          <FacebookShareButton className="px-2 pt-1" url={link} quote={title}>
            <FacebookIcon
             
              round={true}
              size={40}
            ></FacebookIcon>
          </FacebookShareButton>

          <WhatsappShareButton className="px-2 pt-1" quote={title} url={link}>
            <WhatsappIcon
                      

              round={true}
              size={40}
            ></WhatsappIcon>
          </WhatsappShareButton>

          <TwitterShareButton
            className="px-2 pt-1 "
            title={title}
            url={link}
            related={["thebuggubug"]}
          >
            <TwitterIcon
                         

              round={true}
              size={40}
            ></TwitterIcon>
          </TwitterShareButton>
          <div
            className="more-share-link "
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            title="more Share Links"
          >
            <p>...</p>
          </div>
        </div>
      </div>
      <div>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Share This Post On:
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body text-center">
                <EmailShareButton
                  className="px-2"
                  url={link}
                  subject={title}
                  body={"I found this Blog by " + author}
                >
                  <EmailIcon
                               

                    round={true}
                    size={40}
                  ></EmailIcon>
                </EmailShareButton>

                <TelegramShareButton className="px-2 " url={link} title={title}>
                  <TelegramIcon
                                

                    round={true}
                    size={40}
                  ></TelegramIcon>
                </TelegramShareButton>

                <RedditShareButton className="px-2 " url={link} title={title}>
                  <RedditIcon
                              

                    round={true}
                    size={40}
                  ></RedditIcon>
                </RedditShareButton>

                <ViberShareButton className="px-2 " url={link} title={title}>
                  <ViberIcon
                    
                    round={true}
                    size={40}
                  ></ViberIcon>
                </ViberShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareBlog;
