import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import PuffLoader from "react-spinners/PuffLoader";
import sanityClient from "../../client";
import project from "../../assets/Images/project.png";

const Projects = () => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="project"] {
        title,  
        description,
        slug,
        projectURL,
        mainImage{
          asset->{
            _id,
            url
          },
          alt,
        }   
      }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  const options = {
    loop: true,
    margin: 20,
    responsiveClass: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },

      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <div className="container-fluid projects-container">
      <div className="col-md-1 project ">
                <img src={project} alt="projectLogo png" className="img-fluid" />
              </div>

        <div className=" container" id="project">
          <div className="col-md-12">
            <h1>
              Projects <br /> Executed
            </h1>
            <hr className="w-25" />
          </div>

          <div className="row pt-5">
            <div className="col-md-12 pt-4 wow animate__animated animate__fadeInUp">
              <OwlCarousel
                className="owl-theme"
                {...options}
                dots={false}
                autoplay={true}
              >
                <div className="list ">
                  {postData &&
                    postData.map((project, index) => (
                      <a
                        href={project.projectURL}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                      >
                        <div className="projects-cards mx-auto ">
                          <div className="projects-image">
                            <img
                              src={project.mainImage.asset.url}
                              alt={project.title}
                            />
                          </div>
                          <div className="projects-details">
                            <div className="center">
                              <h1>
                                {project.title}
                                <br />
                              </h1>
                              <h6 className="pt-2 project-description">
                                {project.description}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;