import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import PuffLoader from "react-spinners/PuffLoader";

import sanityClient from "../../client";
const KeySkills = ({ keySkill }) => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="keySkills"] | order(publishedAt desc){
        title,
        slug,
        description,
        mainImage{
          asset->{
            _id,
            url
          },
          alt,
          
        }
      }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  const options = {
    loop: true,
    // margin: 30,
    responsiveClass: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },

      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <section>
        <div className="container-fluid key-skills">
          <div className="container text-white py-4">
            <div className="row ">
              <div className="col-md-12 ">
                <h1>
                  Key <br />
                  Skills
                </h1>
                <hr className="w-25" />
              </div>
            </div>

            <div className="row  skills-tray mt-4">
              <div className="col-md-7 text-center p-2 ">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  dots={false}
                  autoplay={true}
                >
                  {postData &&
                    postData.map((keySkills, index) => (
                      <div className="item" key={index}>
                        <div className="skill-icon icon  ">
                          <img
                            className="img-fluid"
                            src={keySkills.mainImage.asset.url}
                            title={keySkills.description}
                            alt={keySkills.title}
                          />
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>

              <div className="col-md-5  py-2  ">
                <p>{keySkill}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KeySkills;
