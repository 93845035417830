import headerImg from "../../assets/Images/rohan.png";
import cyanHash from "../../assets/Images/cyanHash.png";
import redSquare from "../../assets/Images/redSquare.png";

import { Link } from "react-scroll";
const Header = () => {
  return (
    <>
      <main>
        <div className="container-fluid header">
          <div className="container header-container">
            <div className="row header-row text-center mx-auto">
              <div className="col-md-1 cyanHash ">
                <img src={cyanHash} alt="cyanHash png" />
              </div>
              <div className="col-md-5 mx-auto  text-white  my-auto">
                <div
                  className="hello-there"
                  data-wow-duration="2s"
                  data-wow-delay="5s"
                >
                  <h1>
                    Hello There ! <span>👋</span>
                  </h1>
                  <h1>
                    I am{" "}
                    <span className="rohan">
                      <b>Rohan</b>
                    </span>
                  </h1>
                  <i> Web Developer || Photographer</i>
                </div>
                <div>
                  <div className="my-3  wow animate__animated animate__heartBeat animate__repeat-2  animate__slow">
                    <h2>I Design & Develop Web Application/UI</h2>
                  </div>
                </div>
                <div className="text-center  buttons">
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    <button className="btn wow btn-outline-light p-2 m-2 animate__animated animate__fadeInLeft   animate__slow">
                      Connnect to Me
                    </button>
                  </Link>
                  <Link
                    activeClass="active"
                    to="project"
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    <button className="btn  btn-light p-2 m-2 wow animate__animated animate__fadeInRight  ">
                      View Projects
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-md-5 mx-auto d-md-inline  d-none ">
                <img src={headerImg} alt="" className="img-fluid animate__animated animate__fadeInDown animate__fast" />
              </div>

              <div className="col-md-1 redSquare d-md-inline  d-none">
                <img
                  src={redSquare}
                  className="img-fluid"
                  alt="redSquare png"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Header;
