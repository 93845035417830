import "./Home.css";
import Header from "./Header";
import KeySkills from "./KeySkills";
import TechFam from "./TechFam";
import Quote from "./Quote";
import RecentBlogs from "./RecentBlogs";
import Contact from "./Contact";
import About from "./About";
import Projects from "./Projects";
// import Photography from "./Photography";
import Helmet from "react-helmet";
import React, { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import sanityClient from "../../client";

const Home = () => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="content"] {
          homeMeta,
          metaTitle,
            blog,
            publicImg{
              asset->{
                _id,
                url
              },
              alt,
            } ,
                blog,
                techFam,
                contact,
                quote,
                keySkill,
                about
          }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{postData[0].metaTitle}</title>
        <link rel="canonical" href={window.location.href} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content={postData[0].publicImg.asset.url} />

        <meta property="og:title" content={postData[0].metaTitle} />
        <meta property="og:description" content={postData[0].homeMeta} />
        <meta property="og:image" content={postData[0].publicImg.asset.url} />

        <meta itemprop="name" content={postData[0].metaTitle} />
        <meta itemprop="description" content={postData[0].homeMeta} />
        <meta itemprop="image" content={postData[0].publicImg.asset.url} />

        <meta name="twitter:title" content={postData[0].metaTitle} />
        <meta name="twitter:description" content={postData[0].homeMeta} />
        <meta name="twitter:image" content={postData[0].publicImg.asset.url} />
      </Helmet>

      <Header />
      <KeySkills keySkill={postData[0].keySkill} />
      <TechFam tech={postData[0].techFam} />
      <Quote quote={postData[0].quote} />
      <RecentBlogs blogs={postData[0].blog} />
      <Projects />
      <Contact contact={postData[0].contact} />
      <About about={postData[0].about} />
      {/* <Photography /> */}
    </>
  );
};

export default Home;
