// import quoteImg from "../../assets/Images/think.png";
const Quote = ({ quote }) => {
  return (
    <>
      <section>
        <div className="container-fluid quote ">
          <div className="container quote-div ">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className="text-white">  <span className="invCom">" </span>{quote}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Quote;
