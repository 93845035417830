import { Link } from "react-router-dom";
import Helmet from "react-helmet";

const errorPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found !</title>
        <meta
          name="description"
          tags="404"
          content="It seems like we are lost, Lets get back to home and start over"
        />
      </Helmet>
      <section className="page_404 ">
        <div className="container error-container">
          <div className="row ">
            <div className="col-sm-12 ">
              <div className="col-sm-12  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>
                <div className="contant_box_404">
                  <h3 className="h2">Look like We're Lost</h3>
                  <p>The page you are looking for is not avaible !</p>
                  {/* <a href className="link_404">Go to Home</a> */}
                  <button className="btn link_404 text-white">
                    <Link to="/">Home </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default errorPage;
