import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import sanityClient from "../../client";
// import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
// import SideBarBlog from "./SideBarBlog";
// import FeaturedBlog from "./FeaturedBlog";
import Helmet from "react-helmet";
import PuffLoader from "react-spinners/PuffLoader";
import ShareBlog from "./ShareBlog";
import SideBarBlog from "./SideBarBlog";
import FeaturedBlog from "./FeaturedBlog";

export default function SingleBlog() {
  // const builder = imageUrlBuilder(sanityClient);
  // function urlFor(source) {
  //   return builder.image(source);
  // }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
          author,
          title,
          slug,
          tags,
          publishedAt,
          location,
          mainImage{
              asset->{
                      _id,
                      url
                      }
                    },
          body,
          
          "name":author->name,
          // "authorImage":author->image
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!singlePost)
    return (
      <div className="text-center loader my-auto">
        <PuffLoader size={60} />
      </div>
    );

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }

  return (
    <>
      <Helmet>
        <title>Blogs /{singlePost.title}</title>
        <meta tags={singlePost.tags[0]} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content={singlePost.mainImage.asset.url} />

        <meta property="og:title" content={singlePost.title} />
        <meta
          property="og:description"
          content={truncate(`"${singlePost.body[0].children[0].text}`, 200)}
        />
        <meta property="og:image" content={singlePost.mainImage.asset.url} />

        <meta itemprop="name" content={singlePost.title} />
        <meta
          itemprop="description"
          content={truncate(`"${singlePost.body[0].children[0].text}`, 200)}
        />
        <meta itemprop="image" content={singlePost.mainImage.asset.url} />

        <meta name="twitter:title" content={singlePost.title} />
        <meta
          name="twitter:description"
          content={truncate(`"${singlePost.body[0].children[0].text}`, 200)}
        />
        <meta name="twitter:image" content={singlePost.mainImage.asset.url} />
      </Helmet>

      <div className="container-fluid single-blog-header">
        <div className="container">
          <div className="col-md-12 text-white ">
            <div>
              <h1>Blogs</h1>
            </div>
            <div className="my-3">
              <h2>
                <b>" {singlePost.title}</b>
              </h2>
            </div>
            <div>
              <i>by: {singlePost.name}</i>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid single-blog-container">
        <div className="container">
          <nav aria-label="breadcrumb" className=" mt-5 ">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink exact to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {singlePost.title}
              </li>
            </ol>
          </nav>

          <div className="row mt-5 ">
            <div className="col-md-7">
              <div className="card border-0 single-blog-wrapper pt-3">
                <img
                  className="img-fluid text-center px-3"
                  src={singlePost.mainImage.asset.url}
                  alt={singlePost.title}
                  title={singlePost.title}
                />
                <div className="card-body">
                  <div className="single-blog-heading ">
                    <div className="card-title mt-4">
                      <h2>
                        <b>{singlePost.title}</b>
                      </h2>
                    </div>
                    <div className="col">
                      <p>
                        {singlePost.publishedAt} || <b> {singlePost.location}</b>
                      </p>
                    </div>
                  </div>

                  <div className="main-blog-body ">
                    {/* <p > */}
                    <BlockContent blocks={singlePost.body} />
                    {/* </p> */}
                    {/* <p>{singlePost.tags.length}</p> */}
                  </div>
                  <ShareBlog
                    title={singlePost.title}
                    link={window.location.href}
                    author={singlePost.name}
                    imgLink={singlePost.mainImage.asset.url}
                  />
                </div>
                <div className="card-footer mt-2">
                  <div className="col-md-12 ">
                    <p className="">
                      {singlePost.tags[0]} {"| " + singlePost.tags[1]}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <NavLink exact to="/blogs">
                    <p>{"< "}Back to Blogs</p>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col " />

            {/* SideBar Blogs */}

            <div className="col-md-4 mt-5 ">
              <SideBarBlog />
              <FeaturedBlog />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
