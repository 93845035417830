import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import PuffLoader from "react-spinners/PuffLoader";
import { Link } from "react-router-dom";
import sanityClient from "../../client";

const RecentBlogs = ({ blogs }) => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
        title,
        location,
        publishedAt,      
        slug,
        body,
        "name":author->name,
        "authorImage":author->image,
        mainImage{
          asset->{
            _id,
            url
          },
          alt,
          
        }
      }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }

  const options = {
    loop: true,
    // margin: 30,
    responsiveClass: true,
    autoplayHoverPause: true,
    smartSpeed: 500,
    autoplayTimeout:2200,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <>
      <section>
        <div className="container-fluid blog-container p-0 py-5">
          <div className="container wrapper">
            <div className="col-md-12 blog-heading">
              <h1>
                Recent <br />
                Blogs
              </h1>
              <hr className="w-25" />
            </div>
            <div className="row blog-cards mt-5">
              <div className="col-md-7 pt-4 my-auto wow bounceInUp">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  dots={false}
                  autoplay={true}
                >
                  {postData &&
                    postData.map((post, index) => (
                      <Link
                        to={"/blogs/" + post.slug.current}
                        key={post.slug.current}
                      >
                        <div className="item mx-4">
                          <div className=" blog-card-img">
                            <article id={index}>
                              <h2>{truncate(`"${post.title}`, 40)}</h2>
                              {/* <h4>
                                {truncate(
                                  `"${post.body[0].children[0].text}`,
                                  80
                                )}
                              </h4> */}
                            </article>
                            <img
                              src={post.mainImage.asset.url}
                              alt={post.title + " image"}
                              title={post.title + " image"}
                            />
                          </div>
                        </div>
                      </Link>
                    ))}
                </OwlCarousel>
              </div>

              <div className="col-md-5 pt-4 text-white ">
                <p>{blogs}</p>
              </div>
            </div>

            <div className="col-md-4"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentBlogs;
