import bg from "../../assets/Images/bg.jpg";
import { Parallax } from "react-parallax";
const About = ({ about }) => {
  return (
    <>
      <section>
        <div className="container-fluid p-0 about-container">
          <Parallax
            blur={{ min: -2, max: 4 }}
            bgImage={bg}
            bgImageAlt="about paralalx image"
            style={{ minHeight: "28rem" }}
            strength={200}
            
          >
            <div className="container text-white">
              <div className="row">
                <div className="col-md-12 mt-5">
                  <h1>About</h1>
                  <hr className="w-25" />
                </div>
                <div className="col-md-10 mx-auto  my-5">
                  <div className=" px-2 text-white py-4 about-text-container">
                    <p>{about}</p>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </section>
    </>
  );
};

export default About;
