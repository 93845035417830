import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import Helmet from "react-helmet";

import sanityClient from "../../client";
const BlogList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
          title,
          location,
          publishedAt,      
          slug,
          body,
          "name":author->name,
          "authorImage":author->image,
          mainImage{
            asset->{
              _id,
              url
            },
            alt,
            
          }
        }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }

  return (
    <>
      <Helmet>
        <title>Rohan /Blogs</title>
        <link rel="canonical" href={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content={postData[0].mainImage.asset.url} />
        <meta tags="Blogs,Content, web, website, UI, frontend, blogs" />
        {/* <meta property="og:title" content={postData[0].metaTitle} /> */}
        content={truncate(`"${postData[0].body[0].children[0].text}`, 160)}
        <meta property="og:description" />
        <meta property="og:image" content={postData[0].mainImage.asset.url} />
        {/* <meta itemprop="name" content={postData[0].metaTitle} /> */}
        content={truncate(`"${postData[0].body[0].children[0].text}`, 160)}
        <meta itemprop="description" />
        <meta itemprop="image" content={postData[0].mainImage.asset.url} />
        {/* <meta name="twitter:title" content={postData[0].metaTitle} /> */}
        content={truncate(`"${postData[0].body[0].children[0].text}`, 160)}
        <meta name="twitter:description" />
        <meta name="twitter:image" content={postData[0].mainImage.asset.url} />
      </Helmet>


      <div className="container blogList-container ">
        <div className="row">
          {postData &&
            postData.map((post, index) => (
              <div className="col-lg-4 col-md-6">
                <Link
                  to={"/blogs/" + post.slug.current}
                  key={post.slug.current}
                >
                  <div
                    className="card card-effect blogCard border-0 my-4"
                    key={index}
                  >
                    <img
                      className="img-fluid card-img-top rounded"
                      src={post.mainImage.asset.url}
                      alt={post.title}
                    />

                    <div className="card-body pt-0 ">
                      {/* blog Category and location */}
                      <div className="pt-1 row blogCatLoct">
                        <div className="col ">
                          <p className="p-0">{post.location}</p>
                        </div>
                        <div className="col text-end ">
                          <p className="p-0">{post.publishedAt}</p>
                        </div>
                      </div>

                      <div className="card-title BlogList-title text-center ">
                        <b>{truncate(`${post.title}`, 48)}</b>
                      </div>
                      <div className="card-text">
                        <p className="BlogList-bodyText">
                          {truncate(`"${post.body[0].children[0].text}`, 160)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default BlogList;
