import "./Blog.css";
import BlogList from "./BlogList";
const Blog = () => {
  return (
    <>
      <div className="container-fluid blog-header">
        <div className="container">
          <div className="col-md-12 text-white ">
            <div>
              <h1>Blogs</h1>
            </div>
            <div>
              <p>
                <i>
                  All the blogs that I have written and posted <br /> are found
                  in thiis page.
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid blogList-container">
        <BlogList />
      </div>
    </>
  );
};

export default Blog;
