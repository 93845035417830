import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PuffLoader from "react-spinners/PuffLoader";
import sanityClient from "../../client";
import limeTriangle from "../../assets/Images/limeTriangle.png";
import screw from "../../assets/Images/screw.png";


const TechFam = ({ tech }) => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="techFam"] {
        title,   
        slug,
        description,
        mainImage{
          asset->{
            _id,
            url
          },
          alt,
    
        }
      }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  const options = {
    loop: true,
    // margin: 30,
    responsiveClass: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }
  return (
    <>
      <section>
        <div className="container-fluid tech-fam ">
        <div className="col-md-1 screw ">
                <img src={screw} alt="screw png" className="img-fluid" />
              </div>
          <div className="container ">
            <div className="row">
              <div className="col-md-12 ">
                <h1>
                  Technology <br /> Familarities
                  <hr className="w-25" />
                </h1>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-5 p-2  wow animate__animated animate__fadeInLeft">
                <p>{tech}</p>
              </div>
              <div className="col-md-7 my-auto p-2  wow animate__animated animate__fadeInRight">
                <div className="row tech-fam-icon-row mx-auto">
                  <OwlCarousel
                    className="owl-theme"
                    {...options}
                    autoplay={true}
                    dots={false}
                  >
                    {postData &&
                      postData.map((techFam, index) => (
                        <div className="item " key={index}>
                          <div className="card tech-fam-card  mx-auto">
                            <div className="tech-fam-card-body py-2">
                              <div className="tech-fam-img">
                                <img
                                  src={techFam.mainImage.asset.url}
                                  className="card-img-top"
                                  alt={techFam.title}
                                  title={techFam.title}
                                />
                              </div>
                              <div className="card-body">
                                <div className="tech-fam-title  text-center">
                                  <h5 className="card-title ">
                                    {techFam.title}
                                  </h5>
                                </div>
                                <p className="card-text">
                                  {truncate(`${techFam.description}`, 160)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
          <div className="col text-end limeTriangle ">
                <img src={limeTriangle} alt="limeTriangle png" className="img-fluid" />
              </div>
        </div>
      </section>
    </>
  );
};

export default TechFam;