import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import sanityClient from "../../client";

const SideBarBlog = () => {
  const { slug } = useParams();
  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + " . . ." : string;
  }

  function refreshPage() {
  
    window.scrollTo(0, 0);
  }

  const [postData, setPost] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post" && slug.current!="${slug}"] | order(publishedAt desc)[0...4]
        {
        title, 
        slug,
      body,
        mainImage{
          asset->{
            _id,
            url
          },
          alt,
          
        }
      }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, [slug]);
  if (!postData)
    return (
      <div className="text-center">
        <PropagateLoader size={5} />
      </div>
    );

  // console.log(postData.length);

  return (
    <>
      <div className="row">
        <div className="col sidebar-blog-heading  mb-4">
          <h1>
            Recent
            <br />
            <span style={{ color: "#FE5F4F" }}>Blogs</span>{" "}
          </h1>
          <hr className="w-25" />
        </div>
      </div>

      <div className="sidebar-blog-wrapper mb-5 px-3">
        {postData &&
          postData.map((post, index) => (
            <div className="row  p-3 " key={index}>
              <div className="col-xl-4 col-lg-12 col-md-12 sidebar-img  p-0">
                <img
                  src={post.mainImage.asset.url}
                  className="img-fluid"
                  alt={post.tilte}
                ></img>
              </div>

              <div
                className="col-xl-8 col-lg-12 col-md-12 py-2"
                onClick={refreshPage}
                to={post.slug.current}
              >
                {/* sideblog title */}
                <Link to={post.slug.current} key={post.slug.current}>
                  <div className="sidebolg-blogtitle  ">
                    <p className=" mb-1"> {truncate(`${post.title}`, 36)}</p>
                  </div>
                </Link>
                {/* sideblog text */}
                <div className="sidebolg-blogtext ">
                <p >
                  {truncate(`"${post.body[0].children[0].text}`, 60)}
                </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SideBarBlog;
