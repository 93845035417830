import React, { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { SocialIcon } from "react-social-icons";
import sanityClient from "../../client";


import "./Global.css";
const Footer = () => {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="social"] {
          siteTitle,    
          siteURL
      }`
      )
      .then((data) => setPost(data))

      .catch(console.error);
  }, []);
  if (!postData)
    return (
      <div className="loader text-center my-auto">
        <PuffLoader size={60} />
      </div>
    );

  return (
    <>
      <footer>
        <div className="container-fluid footer-container p-0">
          <div className="container  text-white ">
            <div className="row">
              <div className="col-md-7 py-5">
                <h1>
                  Rohan <br /> Manandhar
                </h1>
                <hr className="w-25" />

                <p className="pt-4">
                  Young passionate kid loving to explore new ideas, technique
                  and technological systems. Highly interested in Front-end
                  developing espicially in Web Development. Got partial
                  knowledge on backend. I can make designs and appplications of
                  your choice. A long way to go !! <br /> In case i need to work
                  for you, we can have contacts. You can contact me from these
                  contact infos or send me message, I will get back to you as
                  soon as possible.
                </p>
              </div>
              <hr className="d-md-none w-25 mx-auto " />
              <div className="col-md-1" />
              <div className="col-md-4 py-5">
                <h1>
                  Connecting <br /> Links
                </h1>
                <hr className="w-25" />

                <ul className="pt-4">
                  <li> 44600, Kathmandu Nepal</li>
                  <li> rohansayami@gmail.com</li>
                  {/* <li> Send Feedback or Give Testimony</li> */}
                  {/* <li> Report Bug</li> */}
                  {/* <li> About This Website</li> */}
                </ul>
              </div>
              <hr className="d-md-none w-25 mx-auto " />
            </div>

            <div className="row">
              <div className="col-md-12 icon-bar text-center">
                {postData &&
                  postData.map((social, index) => (
                    <span key={index}>
                      <SocialIcon
                        url={social.siteURL}
                        className="swing mx-2 my-1"
                        target="_blank"
                        fgColor="#fff"
                        title={social.siteTitle}
                      />
                    </span>
                  ))}
              </div>

              <div className="col-md-10 pt-5 text-center mx-auto">
                <p>
                  Designed and Developed by <b>Rohan Manandhar</b>. All the
                  above contents are subject to Copyright. <br /> © Rohan
                  {" " + new Date().getFullYear()}
                </p>
                <hr className="d-md-none w-25 mx-auto " />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
