import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <nav className="navbar container navbar-expand-lg navbar-dark py-3 fixed-top d-lg-inline ">
        <div className="container nav-container">
          <Link  to="/" className="navbar-brand">
            Rooohan
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <span className="navbar-toggler-icon" />.
          </button>
          <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto text-center">
              <li className="nav-item">
                <Link  to="/blogs">
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
// d-md-none d-none
